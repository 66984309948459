<template>
  <page-layout ref="layout" @refresh="refresh">
    <template #breadcrumbs="{ }">
      <b-breadcrumb-item :text="`Printable - ${$store.state.settings.app.current.title}`" />
      <b-breadcrumb-item text="Magazine and Program Data" active />
    </template>

    <template #dropdown-options>
      <b-dropdown-item @click="refresh">
        <feather-icon icon="RotateCwIcon"/>
        <span class="align-middle ml-50">Refresh</span>
      </b-dropdown-item>
    </template>

    <template #content="{ state }">
      <table-layout ref="table-layout"
                    :items="table.items"
                    :loading="table.loading"
                    :fields="table.fields"
                    :filters="table.filters" :filters-options="{ visible: true, collapsed: true }"
                    :paging="table.paging"
                    :sorting="table.sorting"
                    :export-exclude-fields="[
                        'id',
                        'application.id',
                        'application.instrument.id',
                        'application.student.school.zone.id',
                        'ensemble.id',
                        'school.id',
                      ]"
                    @mounted="table = $event"
                    @updated="table = $event">

        <template #overlay>
          <overlay-loading :items="[
            { state: table.loading, desc: 'Loading Selections', loaded: table.loaded},
            { state: state.loading, desc: 'Rendering Template'},
          ]" />
        </template>

        <template #filters>
          <b-row>
            <b-col>
              <b-form-group label="Ensemble" label-for="filter-ensemble-input">
                <v-select id="filter-ensemble-input"
                          v-model="table.filters.ensemble.value"
                          :options="options.ensembles.items"
                          :loading="options.ensembles.loading"
                          :reduce="ensemble => ensemble.id"
                          label="name"
                          :select-on-tab="true"
                          :clearable="true"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          class="w-100 d-print-none"/>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Instrument" label-for="filter-instrument-input">
                <v-select id="filter-instrument-input"
                          v-model="table.filters.instrument.value"
                          :options="options.instruments.items"
                          :loading="options.instruments.loading"
                          :reduce="instrument => instrument.id"
                          label="name"
                          :select-on-tab="true"
                          :clearable="true"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          class="w-100 d-print-none"/>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Zone" label-for="filter-ensemble-input">
                <v-select id="filter-zone-input"
                          v-model="table.filters.zone.value"
                          :options="options.zones.items"
                          :loading="options.zones.loading"
                          :reduce="zone => zone.id"
                          label="name"
                          :select-on-tab="true"
                          :clearable="true"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          class="w-100 d-print-none"/>
              </b-form-group>
            </b-col>
          </b-row>
        </template>

        <!-- Ensemble-->
        <template #cell(ensemble.name)="{data}">
          <span v-if="hasValue(data, 'item.ensemble')">
            {{ data.item.ensemble.name }}
          </span>
          <span v-else class="text-danger">No Ensemble</span>
        </template>

        <!-- Instrument-->
        <template #cell(application.instrument.name)="{data}">
          <span v-if="hasValue(data, 'item.application.instrument')">
            {{ data.item.application.instrument.name }}
          </span>
          <span v-else class="text-danger">No Instrument</span>
        </template>

        <!-- School-->
        <template #cell(application.student.school.name)="{data}">
          <span v-if="hasValue(data, 'item.application.student.school.name')">
            {{ data.item.application.student.school.name.legal }}
          </span>
          <span v-else class="text-danger">No School</span>
        </template>

        <!-- District -->
<!--        <template #cell(application.student.school.district.name)="{data}">
          <span v-if="hasValue(data, 'item.application.student.school.district.name')">
            {{ data.item.application.student.school.district.name.legal }}
          </span>
          <span v-else class="text-danger">No District</span>
        </template>-->

        <!-- County -->
        <template #cell(application.student.school.address.county)="{data}">
          <span v-if="hasValue(data, 'item.application.student.school')">
            {{ data.item.application.student.school.address.county }}
          </span>
          <span v-else class="text-danger">No County</span>
        </template>

        <!-- Zone -->
        <template #cell(application.student.school.zone.name)="{data}">
          <span v-if="hasValue(data, 'item.application.student.school.zone.name')">
            {{ data.item.application.student.school.zone.name }}
          </span>
          <span v-else class="text-danger">No Zone</span>
        </template>


      </table-layout>
    </template>

    <template #debug>

    </template>
  </page-layout>
</template>

<script>
import PageLayout from '@/components/PageLayout.vue';
import {API, graphqlOperation} from 'aws-amplify';
import {listSelections} from '@/graphql/queries/printable-magazine-and-program';
import vSelect from 'vue-select';
import OverlayLoading from '@/components/OverlayLoading.vue';
import TableLayout from '@/components/TableLayout.vue';
import lodashMixin from '@/mixins/lodash.mixin';
import settingsMixin from '@/mixins/settings.mixin';

export default {
  name: 'MagazineAndProgram',
  components: {
    TableLayout,
    OverlayLoading,
    PageLayout,
    vSelect
  },
  mixins: [lodashMixin, settingsMixin],
  data() {
    return {
      table: {
        loading: false,
        items: [],
        fields: [
          {
            key: 'application.student.name.first',
            label: 'First Name',
            sortable: false,
            filterable: false,
            visible: true,
            tdClass: 'align-middle'
          },
          {
            key: 'application.student.name.last',
            label: 'Last Name',
            sortable: false,
            filterable: false,
            visible: true,
            tdClass: 'align-middle'
          },
          {
            key: 'ensemble.name',
            label: 'Ensemble',
            sortable: false,
            filterable: false,
            visible: true,
            tdClass: 'align-middle'
          },
          {
            key: 'application.instrument.name',
            label: 'Instrument',
            sortable: false,
            filterable: false,
            visible: true,
            tdClass: 'align-middle'
          },
          {
            key: 'application.instrument.order',
            label: 'Order',
            sortable: false,
            filterable: false,
            visible: false,
            tdClass: 'align-middle'
          },
          {
            key: 'part',
            label: 'Part',
            sortable: false,
            filterable: false,
            visible: true,
            tdClass: 'align-middle'
          },
          {
            key: 'application.student.school.name',
            label: 'School',
            sortable: false,
            filterable: false,
            visible: true,
            tdClass: 'align-middle'
          },
          {
            key: 'application.student.school.district.name.legal',
            label: 'District',
            sortable: false,
            filterable: false,
            visible: true,
            tdClass: 'align-middle'
          },
          {
            key: 'application.student.school.address.county',
            label: 'County',
            sortable: false,
            filterable: true,
            visible: true,
            tdClass: 'align-middle'
          },
          {
            key: 'application.student.school.zone.name',
            label: 'Zone',
            sortable: false,
            filterable: true,
            visible: true,
            tdClass: 'align-middle'
          },
        ],
        search: {
          type: '\'',
          value: '',
          options: {
            types: [
              { text: 'Fuzzy', value: '' },
              { text: 'Exact', value: '=' },
              { text: 'Includes', value: '\'' },
              { text: 'Starts With', value: '^' },
            ]
          },
        },
        paging: {
          size: 0,
        },
        sorting: {
          by: null,
          comparator: (a, b) => this.compareByInstrumentOrderAndPart(a, b),
          comparatorKey: 'iop',
          comparators: [
            { key: 'iop', text: 'Instrument Order, Part', comparator: (a, b) => this.compareByInstrumentOrderAndPart(a, b) },
          ]
        },
        filters: {
          ensemble: { key: 'ensemble.id', value: null },
          instrument: { key: 'application.instrument.id', value: null },
          zone: { key: 'application.student.school.zone.id', value: null },
        },
      },
      options: {
        ensembles: {
          items: [],
          loading: true
        },
        instruments: {
          items: [],
          loading: true
        },
        zones: {
          items: [],
          loading: true
        }
      },
    }
  },
  async mounted() {
    await this.listSelections()
    this.$refs.layout.state.loading = false
  },
  methods: {
    async listSelections(nextToken, pagedSelections) {
      const selections = pagedSelections || []
      const input = {
        limit: 500,
        filter: {
          accepted: { eq: true },
          createdAt: {
            between: [
              this.settingsStore.app.current.year.start,
              this.settingsStore.app.current.year.end
            ]
          }
        },
        nextToken: nextToken,
      }

      const response = await API.graphql(graphqlOperation(listSelections, input));
      selections.push(...response.data.listSelections.items);

      if(response.data.listSelections.nextToken) {
        await this.listSelections(response.data.listSelections.nextToken, selections)
      }
      else {
        this.table.items = selections;
        this.table.items.sort(this.compareByInstrumentOrderAndPart)
        this.table.loading = false

        //Populate Ensemble Options
        const ensembles = {};
        this.table.items.forEach(item => {
          ensembles[item?.ensemble?.id] = {
            id: item?.ensemble?.id,
            name: item?.ensemble?.name,
          };
        })
        this.options.ensembles.items = Object.values(ensembles).filter(item => item.id).sort((a, b) => a.name.localeCompare(b.name))
        if(!this.options.ensembles.items.some(item => item.id === this.table.filters.ensemble.value)) {
          this.table.filters.ensemble.value = null
        }
        this.options.ensembles.loading = false

        //Populate Instrument Options
        const instrumentsMap = {};
        this.table.items.forEach(item => {
          instrumentsMap[item?.application?.instrument?.id] = {
            id: item?.application?.instrument?.id,
            name: item?.application?.instrument?.name,
          };
        })
        this.options.instruments.items = Object.values(instrumentsMap).filter(item => item.id).sort((a, b) => a.name.localeCompare(b.name))
        if(!this.options.instruments.items.some(item => item.id === this.table.filters.instrument.value)) {
          this.table.filters.instrument.value = null
        }
        this.options.instruments.loading = false

        //Populate Zone Options
        const zonesMap = {};
        this.table.items.forEach(item => {
          zonesMap[item.application?.student?.school?.zone?.id] = {
            id: item?.application?.student?.school?.zone?.id,
            name: item?.application?.student?.school?.zone?.name,
          };
        })
        this.options.zones.items = Object.values(zonesMap).filter(item => item.id).sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' }))
        if(!this.options.zones.items.some(item => item.id === this.table.filters.zone.value)) {
          this.table.filters.zone.value = null
        }
        this.options.zones.loading = false
      }
    },
    async refresh() {
      this.table.loading = true
      this.options.ensembles.loading = true
      this.options.instruments.loading = true
      this.options.zones.loading = true
      await this.listSelections()
    },

    /** Sorting **/
    compareByInstrumentOrderAndPart(a, b) {
      return (a?.application?.instrument ? a.application.instrument.order : 0) - (b?.application?.instrument ? b.application.instrument.order : 0)
          || a?.part?.localeCompare(b?.part, undefined, { numeric: true, sensitivity: 'base' })
          || a?.application?.student?.name?.last?.localeCompare(b?.application?.student?.name?.last)
          || a?.application?.student?.name?.first?.localeCompare(b?.application?.student?.name?.first)
    },
  }
}
</script>

<style scoped>
@media print
{
  .print-title
  {
    display: block !important;
  }
}
</style>
