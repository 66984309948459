// eslint-disable-next-line import/prefer-default-export
export const listSelections = /* GraphQL */ `
    query ListSelections(
        $id: ID
        $filter: ModelSelectionFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listSelections(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                part
                ensemble {
                    id
                    name
                }
                application {
                    id
                    instrument {
                        id
                        name
                        order
                    }
                    student {
                        name {
                            first
                            last
                        }
                        school {
                            name {
                                legal
                            }
                            address {
                                county
                            }
                            zone {
                                id
                                name
                            }
                            district {
                                name {
                                    legal
                                }
                            }
                        }
                    }
                }
            }
            nextToken
        }
    }
`;

export const listEnsembles = /* GraphQL */ `
    query ListEnsembles(
        $id: ID
        $filter: ModelEnsembleFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listEnsembles(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                name
            }
            nextToken
        }
    }
`;
