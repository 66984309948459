var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page-layout',{ref:"layout",on:{"refresh":_vm.refresh},scopedSlots:_vm._u([{key:"breadcrumbs",fn:function(ref){return [_c('b-breadcrumb-item',{attrs:{"text":("Printable - " + (_vm.$store.state.settings.app.current.title))}}),_c('b-breadcrumb-item',{attrs:{"text":"Magazine and Program Data","active":""}})]}},{key:"dropdown-options",fn:function(){return [_c('b-dropdown-item',{on:{"click":_vm.refresh}},[_c('feather-icon',{attrs:{"icon":"RotateCwIcon"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Refresh")])],1)]},proxy:true},{key:"content",fn:function(ref){
var state = ref.state;
return [_c('table-layout',{ref:"table-layout",attrs:{"items":_vm.table.items,"loading":_vm.table.loading,"fields":_vm.table.fields,"filters":_vm.table.filters,"filters-options":{ visible: true, collapsed: true },"paging":_vm.table.paging,"sorting":_vm.table.sorting,"export-exclude-fields":[
                        'id',
                        'application.id',
                        'application.instrument.id',
                        'application.student.school.zone.id',
                        'ensemble.id',
                        'school.id' ]},on:{"mounted":function($event){_vm.table = $event},"updated":function($event){_vm.table = $event}},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('overlay-loading',{attrs:{"items":[
            { state: _vm.table.loading, desc: 'Loading Selections', loaded: _vm.table.loaded},
            { state: state.loading, desc: 'Rendering Template'} ]}})]},proxy:true},{key:"filters",fn:function(){return [_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Ensemble","label-for":"filter-ensemble-input"}},[_c('v-select',{staticClass:"w-100 d-print-none",attrs:{"id":"filter-ensemble-input","options":_vm.options.ensembles.items,"loading":_vm.options.ensembles.loading,"reduce":function (ensemble) { return ensemble.id; },"label":"name","select-on-tab":true,"clearable":true,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.table.filters.ensemble.value),callback:function ($$v) {_vm.$set(_vm.table.filters.ensemble, "value", $$v)},expression:"table.filters.ensemble.value"}})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label":"Instrument","label-for":"filter-instrument-input"}},[_c('v-select',{staticClass:"w-100 d-print-none",attrs:{"id":"filter-instrument-input","options":_vm.options.instruments.items,"loading":_vm.options.instruments.loading,"reduce":function (instrument) { return instrument.id; },"label":"name","select-on-tab":true,"clearable":true,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.table.filters.instrument.value),callback:function ($$v) {_vm.$set(_vm.table.filters.instrument, "value", $$v)},expression:"table.filters.instrument.value"}})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label":"Zone","label-for":"filter-ensemble-input"}},[_c('v-select',{staticClass:"w-100 d-print-none",attrs:{"id":"filter-zone-input","options":_vm.options.zones.items,"loading":_vm.options.zones.loading,"reduce":function (zone) { return zone.id; },"label":"name","select-on-tab":true,"clearable":true,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.table.filters.zone.value),callback:function ($$v) {_vm.$set(_vm.table.filters.zone, "value", $$v)},expression:"table.filters.zone.value"}})],1)],1)],1)]},proxy:true},{key:"cell(ensemble.name)",fn:function(ref){
          var data = ref.data;
return [(_vm.hasValue(data, 'item.ensemble'))?_c('span',[_vm._v(" "+_vm._s(data.item.ensemble.name)+" ")]):_c('span',{staticClass:"text-danger"},[_vm._v("No Ensemble")])]}},{key:"cell(application.instrument.name)",fn:function(ref){
          var data = ref.data;
return [(_vm.hasValue(data, 'item.application.instrument'))?_c('span',[_vm._v(" "+_vm._s(data.item.application.instrument.name)+" ")]):_c('span',{staticClass:"text-danger"},[_vm._v("No Instrument")])]}},{key:"cell(application.student.school.name)",fn:function(ref){
          var data = ref.data;
return [(_vm.hasValue(data, 'item.application.student.school.name'))?_c('span',[_vm._v(" "+_vm._s(data.item.application.student.school.name.legal)+" ")]):_c('span',{staticClass:"text-danger"},[_vm._v("No School")])]}},{key:"cell(application.student.school.address.county)",fn:function(ref){
          var data = ref.data;
return [(_vm.hasValue(data, 'item.application.student.school'))?_c('span',[_vm._v(" "+_vm._s(data.item.application.student.school.address.county)+" ")]):_c('span',{staticClass:"text-danger"},[_vm._v("No County")])]}},{key:"cell(application.student.school.zone.name)",fn:function(ref){
          var data = ref.data;
return [(_vm.hasValue(data, 'item.application.student.school.zone.name'))?_c('span',[_vm._v(" "+_vm._s(data.item.application.student.school.zone.name)+" ")]):_c('span',{staticClass:"text-danger"},[_vm._v("No Zone")])]}}],null,true)})]}},{key:"debug",fn:function(){return undefined},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }